<template>
  <div>
    <HeaderBlock :block="entry.headerBlock" />
    <ArticleBlocks :blocks="entry.articleBlocks" />
  </div>
</template>
<script>
import gql from 'graphql-tag';
import ArticleBlocks, { ArticleBlocksMatrixFragment } from '@/components/article/ArticleBlocks';
import HeaderBlock, { HeaderBlockFragment } from '@/components/common/headerBlock/HeaderBlock';

export const ServicePageFragment = gql`
  fragment ServicePageFragment on service_Entry {
    headerBlock {
      ...HeaderBlockFragment
    }
    articleBlocks {
      ...ArticleBlocksMatrixFragment
    }
    seoTopics
  }
  ${HeaderBlockFragment}
  ${ArticleBlocksMatrixFragment}
`;

export default {
  name: 'ServicePage',
  components: { HeaderBlock, ArticleBlocks },
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
};
</script>
