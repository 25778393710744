<template>
  <ServicePage :entry="entry" />
</template>
<script>
import gql from 'graphql-tag'
import { stripLocale } from '@/utils/i18n'
import entryPage, { SeomaticFragment, EntryPageFragment } from '@/mixins/entryPage'
import ServicePage, { ServicePageFragment } from '@/components/service/ServicePage'

export const ServiceQuery = gql`
  query ServiceQuery($site: String!, $uri: String!) {
    entry(section: "service", site: [$site], uri: [$uri]) {
      typeHandle
      ...EntryPageFragment
      ...ServicePageFragment
    }

    seomatic(site: $site, uri: $uri, asArray: true) {
      ...SeomaticFragment
    }
  }

  ${EntryPageFragment}
  ${ServicePageFragment}
  ${SeomaticFragment}
`

export default {
  name: 'ServicePageController',
  components: { ServicePage },
  mixins: [entryPage],
  async asyncData({ $craftRequest, $retourRedirect, app, params, error, route }) {
    const { locale } = app.i18n
    const uri = stripLocale(route.path, locale)
    const variables = { site: locale, uri }
    const { entry, seomatic } = await $craftRequest(ServiceQuery, variables)
    if (entry === null) {
      return $retourRedirect()
    }
    return { entry, seomatic }
  },
  mounted() {
    this.setBreadCrumb()
    this.gtmPushPageMeta()
  }
}
</script>
